<!--导入excel文件组件-->
<!--
  action:绑定的上传的接口
  showDownModal:{
    isShow:是否显示下载模板按钮
    downLink:下载模板的本地链接地址 如：./excelfiles/xxx.xlsx
    downName:下载模板之后的文件名
  }
  maxSize:上传的文件大小限制，默认10Mb
  uploadData:上传文件时候带的参数，对象
  isShow:数字格式，清空数据专用，绑定之后每次自增1即可清空

  this.$emit('success') :成功之后触发的事件
-->

<template>
  <div>
      <Upload
            ref="excelUpload"
            type="drag"
            :action="action"
            name="file"
            :headers="headers"
            :max-size="maxSize"
            :show-upload-list="false"
            :before-upload="beforeExcelUpload"
            :on-success="uploadSuccess"
            :on-error="errorUpload"
            :data="uploadData"
            :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
            :on-format-error="uploadFormatError"
            :on-exceeded-size="uploadSizeError"
          >
            <div style="padding: 20px 0; text-align: center; width: 100%">
              <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
              <p>点击上传，或将文件拖拽到此处</p>
            </div>
          </Upload>
          <div v-if="excelFile != null">当前选择的文件名称:{{ excelFile.name }}</div>
          <div v-html="errorContent"></div>
          <div style="text-align: center; margin: 20px 0">
            <Button v-if="showDownModal.isShow" type="primary" style="margin-right: 20px">
              <a :href="showDownModal.downLink" :download="showDownModal.downName" style="color: #fff">模板下载</a>
            </Button>
            <Button type="info" :loading="loadingBtn" @click="submitExcel">确认导入</Button>
          </div>
  </div>
</template>

<script>

  export default {
    props:{
        action:{
            type:String,
            default:()=>{
                return ''
            }
        },
        showDownModal:{
          type:Object,
          default:()=>{
            return {}
          }
        },
        maxSize:{
          type:Number,
          default:()=>{
            return 10240
          }
        },
        uploadData:{
          type:Object,
          default:()=>{
            return {}
          }
        },
        isShow:{
          type:Number,
          default:()=>{
            return 0
          }
        },
        isSumbit:{
          type:Boolean,
          default:()=>{
            return false
          }
        },
        headers: {
          type:Object,
          default:()=>{
            return {}
          }
        }
    },
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      //这里存放数据
      return {
        //excel文件数据
        excelFile: null,
        //导入参数
        errorContent: "",
        // 导入转圈圈按钮
        loadingBtn:false
      }
    },
    //方法集合
    methods: {
    //格式验证，如果格式错误，跑这个回调函数
    uploadFormatError(file) {
      this.$Message.destroy();
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" +
          file.name +
          "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件"
         });
      },
      //确认导入
      submitExcel() {
        if(this.isSumbit){
          this.$emit('sumbitUpload')
        }else{
          // if(this.uploadData.orgCode == '' || this.uploadData.orgCode == undefined){
          //   this.$Message.warning({
          //     content:'请先选择社区',
          //     background:true
          //   })
          //   return
          // }
          if (this.excelFile) {
            this.loadingBtn = true
            this.$refs.excelUpload.post(this.excelFile);
          } else {
            this.$Message.warning({
              background: true,
              content: "请先选择文件"
            });
          }
        }
        
      },
      //导入excel文件大小超出限制
      uploadSizeError(file) {
        this.loadingBtn = false
        this.$Notice.error({
          title: "导入失败",
          desc: "文件 " + file.name + " 大小已超出限制，请控制在10M以内"
        });
      },
      //上传之前
      beforeExcelUpload(file) {
        this.loadingBtn = false
        this.excelFile = file;
        return false;
      },
      //excel文件上传成功后执行
      uploadSuccess(res) {
        this.$Message.destroy();
        this.loadingBtn = false
        if (res.code == 200 && res.dataList.length == 0) {
          this.$Message.success({
            background: true,
            content: "导入成功！"
          });
          this.$emit('success')
        } else if (res.code == 200 && res.dataList.length > 0) {
          this.errorContent = "";
          res.dataList.map((item, index) => {
            this.errorContent += `<div style="color:red">${
              item
            }</div>`;
          });
          this.$Notice.error({
            title: "其他数据正常导入",
            desc: this.errorContent,
            duration: 0
          });
          this.$emit('success')
        } else {
          this.$Message.destroy();
          this.$Notice.error({
            title: "导入失败",
            desc: "",
            duration: 2
          });
          // this.errorContent = "";
          // res.dataList.map((item, index) => {
          //   this.errorContent += `<div style="color:red">第${item.number +
          //     4}行：${item.errorCode}</div>`;
          // });
          // this.$Notice.error({
          //   title: "导入失败",
          //   desc: this.errorContent,
          //   duration: 0
          // });
        }
      },
       errorUpload() {
         this.loadingBtn = false
        this.$Message.destroy();
        this.$Notice.error({
          title: "导入失败",
          desc: "",
          duration: 2
        });
      },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {},
    watch:{
      isShow:{
        handler(val,newVal){
          this.excelFile = null
          this.errorContent = null
        }
      }
    }
  }
</script>
<style lang='less' scoped>
  //编写css
</style>